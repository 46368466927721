import React, {useState, useEffect} from 'react';

import {isEmpty, get} from 'utils/helpers';
import {validatePhotos} from 'utils/validateForm';
import {parsePhoto} from 'utils/helpers';
import Photo from 'features/generic/components/Photo/Photo';
import Label from 'features/form/components/Label/Label';

import {Container, FileInput, PhotoContainer, Plus} from './styles';
import {ErrorStyled} from '../../styles';
import PhotoStyled from '../../../generic/components/PhotoStyled/PhotoStyled';

const validFileTypes = ['image/jpeg', 'image/png'];

const PhotoInputStyled = (props) => {
    const [state, setState] = useState({
        uploadErrors: '',
        url: '',
    });

    const handleUpload = (e) => {
        const file = e.target.files[0];
        const photoErrors = [{file_name: file}].reduce(validatePhotos, {});

        if (!isEmpty(photoErrors)) {
            setState({
                uploadErrors: photoErrors[0].file_name,
            });

            return;
        }

        parsePhoto(file, false).then((url) => {
            setState({
                url,
                uploadErrors: '',
            });

            if (props.input) {
                props.input.onChange(file);
            }

            if (props.handleChange) {
                props.handleChange(e);
            }
        });
    };

    useEffect(() => {
        let url = '';

        if (!props.input && !props.input.value) {
            url = props.src || '';
        }

        if (typeof props.input.value === 'string') {
            url = props.input.value;
        }

        if (typeof props.input.value === 'object') {
            parsePhoto(props.input.value, false).then((url) => {
                setState({
                    url,
                });
            });

            return;
        }

        setState({
            url,
        });
    }, []);

    useEffect(() => {
        if (props.input.value === state.url) return;

        if (props.input && typeof props.input.value === 'string') {
            setState({
                url: props.input.value,
            });
            return;
        }

        parsePhoto(props.input.value, false).then((url) => {
            setState({
                url,
            });
        });
    }, [props.input.value]);

    const {
        className = '',
        photoClassName = '',
        inputClassName = 'photo-upload__img',
        width = null,
        height = null,
        imageName = 'Photo',
        photo = null,
        label = '',
    } = props;
    let error = get(props, 'meta.error');

    if (state.uploadErrors) {
        error = state.uploadErrors;
    }

    return (
        <Container $css={props.$css} className={this?.props?.className}>
            {label && (
                <Label
                    required={props.required}
                    htmlFor={props.input ? props.input.name : props.name}>
                    {label}
                </Label>
            )}
            {error && <ErrorStyled>{error}</ErrorStyled>}
            <PhotoContainer src={state.url} width={width} height={height}>
                <PhotoStyled
                    src={state.url}
                    width={width}
                    height={height}
                    $css={props.photoCss}
                    className={this?.props?.className}
                />
                {<Plus width={width} height={height} />}
                <FileInput
                    type="file"
                    name={props.name}
                    onChange={(e) => {
                        handleUpload(e);
                        e.stopPropagation();
                    }}
                />
            </PhotoContainer>
        </Container>
    );
};

export default PhotoInputStyled;
