import React from 'react';

import {ImageDiv, PhotoDiv} from './styles';

const PhotoStyled = (props) => {
    return (
        <PhotoDiv
            $css={props.$css}
            className={props.className}
            width={props.width}
            onClick={props.handleClick}>
            <ImageDiv
                crossOrigin="anonymous"
                src={props.src}
                width={props.width}
                height={props.height}
            />
        </PhotoDiv>
    );
};

export default PhotoStyled;
