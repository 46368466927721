import {COLORS, darken, lighten} from 'core/styles';

export let button = (props) => {
    let styles = {
        minWidth: '115px',
        textTransform: 'uppercase',
        backgroundColor: COLORS.primary,
        color: '#fff',
        fontSize: '11px',
        padding: '10px 15px',
        fontWeight: 900,
        cursor: 'pointer',

        '&:hover': {
            backgroundColor: lighten(COLORS.primary, 5),
        },

        '&:active': {
            backgroundColor: darken(COLORS.primary, 10),
        },
    };

    const hasSucceeded = props.submitSucceeded && props.valid;
    const hasFailed = props.submitFailed && (props.error || !props.valid);
    const hasSubmitted = hasSucceeded || hasFailed;

    let disabled = false;

    if (hasSubmitted) {
        disabled = !(props.submitSucceeded && props.valid);
    } else {
        disabled = props.disabled || props.submitting || props.pristine;
    }

    styles = {
        ...styles,
        opacity: disabled ? 0.5 : 1,
    };

    if (hasSubmitted && props.submitSucceeded && props.valid) {
        styles = {
            ...styles,
            backgroundColor: darken(COLORS.success, 40),
            '&:hover': {
                backgroundColor: darken(COLORS.success, 40),
            },
        };
    }

    if (hasSubmitted && !(props.submitSucceeded && props.valid)) {
        styles = {
            ...styles,
            backgroundColor: darken(COLORS.danger, 5),
            '&:hover': {
                backgroundColor: darken(COLORS.danger, 5),
            },
        };
    }

    return Object.assign(styles, props.style);
};
