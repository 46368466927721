import React from 'react';

import * as styles from './styles';

const Label = (props) => (
    <label htmlFor={props.htmlFor} style={styles.getLabelStyles(props)}>
        {props.children}
    </label>
);

export default Label;
