import {post, upload} from 'core/configureRequest';
import {refreshToken} from 'features/auth/actions';
import {get, maybeIncludeAnalytics} from 'utils/helpers';
import {EVENT_CATEGORIES} from 'core/constants';
import {selectToken} from 'features/auth/selectors';

const reportMap = {
    'click-call-impression-by-day': EVENT_CATEGORIES.Dashboard,
    'tracking-and-transaction-by-event': EVENT_CATEGORIES.Transactions,
    'call-log': EVENT_CATEGORIES.Dashboard,
};

const startDownload = (data) => {
    const reportName = get(data, 'body.report_name');

    return maybeIncludeAnalytics(reportMap[reportName], {
        type: 'REQUEST_DOWNLOAD',
        meta: {
            analytics: {
                category: reportMap[reportName],
                label: reportName,
            },
        },
    });
};

const downloadFile = (downloadPath, data) => ({
    type: 'DOWNLOAD_FILE',
    downloadPath,
    data,
});

export const downloadComplete = () => ({
    type: 'DOWNLOAD_COMPLETE',
});

export const requestUpload = (uploadPath) => ({
    type: 'REQUEST_UPLOAD',
    uploadPath,
});

export const receiveUpload = () => ({
    type: 'RECEIVE_UPLOAD',
});

export const receiveUploadFailure = () => ({
    type: 'RECEIVE_UPLOAD_FAILURE',
});

export const download = (pathname, data) => (dispatch, getState) => {
    const token = selectToken(getState());
    return fetch('/download' + data.url, {
        method: data.method,
        headers: {
            Authorization: token,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data.body),
    })
        .then(async (res) => {
            const result = await res.text();

            if (result) {
                const now = new Date();
                const shortYear = now.getFullYear().toString().slice(2);
                const month = ('0' + (now.getMonth() + 1)).slice(-2);
                const day = ('0' + now.getDate()).slice(-2);
                const hours = now.getHours();
                const minutes = now.getMinutes();
                const reportName = (data.body && data.body.report_name) || 'report';

                const filename = `${reportName}-${shortYear}${month}${day}${hours}${minutes}.csv`;

                const url = window.URL.createObjectURL(new Blob([result]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                link.remove();
            }

            return result;
        })
        .catch((e) => ({success: false, message: e.message}));
};

export const uploadFiles = (pathname, data) => (dispatch, getState) => {
    dispatch(requestUpload(pathname));

    return upload(pathname, data)
        .then((data) => {
            if (!data || !data.success) {
                return Promise.reject(data);
            }

            dispatch(receiveUpload(data));

            return data;
        })
        .catch((e) => {
            console.warn(e);
            dispatch(receiveUploadFailure());
            return Promise.reject(e);
        });
};
