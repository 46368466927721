import {COLORS, bp} from 'core/styles';
import styled from 'styled-components';

export const Container = styled.div`
    margin-bottom: 15px;
    max-width: 250px;
    width: 100%;
    position: relative;
    ${(props) => (props.style ? props.style : '')};
`;

export const StyledInput = styled.input`
    line-height: normal;
    padding: 10px;
    font-size: 13px;
    font-weight: bold;
    margin-right: 20px;
    margin-bottom: 10px;
    border-style: none;
    width: 100%;
    border-width: 1px;
    border-radius: 2px;
    color: ${COLORS.gray};
    box-shadow: inset 2px 2px 1px rgba(0, 0, 0, 0.1),
        inset -1px -1px 1px rgba(0, 0, 0, 0.1);

    ${bp('md')} {
        box-shadow: inset 2px 2px 1px rgba(0, 0, 0, 0.1),
            inset -1px -1px 1px rgba(0, 0, 0, 0.1);
    }

    &:disabled {
        opacity: 0.5;
    }

    ${({meta}) =>
        meta &&
        !meta.active &&
        meta.error &&
        `
    box-shadow: inset 2px 2px 1px rgba(0, 0, 0, 0.1), inset -1px -1px 1px rgba(0, 0, 0, 0.1), inset 0 0 2px #e01e63;
  `}

    ${({inputStyle}) => inputStyle};
`;
