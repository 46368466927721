import styled from 'styled-components';

export let PhotoDiv = styled.div`
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
    min-width: ${(props) => (props.width ? props.width + 'px' : 'none')};
    ${(props) => (props.$css ? props.$css : '')};
`;

const processSrc = (props) => {
    if (!props.src) {
        return '';
    }

    let url = '';

    if (props.src && typeof props.src === 'string') {
        url = props.src.match(/\s/g) ? encodeURI(props.src) : props.src;
    }

    if (url && url.split(';').length > 2) {
        url = url.split(';').slice(1, url.split(';').length).join(';');
    }

    return url;
};

export const ImageDiv = styled.div`
    background-size: cover;
    background-position: center;
    background-image: ${(props) =>
        props.src ? 'url("' + processSrc(props) + '")' : 'none'};
    width: ${(props) => (props.width ? props.width + 'px' : '100%')};
    height: ${(props) => (props.height ? props.height + 'px' : '100%')};
`;
